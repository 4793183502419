<template>
  <div class="prose p-4 pb-0">
    <h2 class="text-center">Terms & Conditions</h2>
    <p>First and foremost, we will always do what is right!</p>
    <p>
      Any gigs cancelled by the homeowner within 24 hours of the scheduled time
      will incur a 1 hour charge for hourly jobs and a 20% charge for fixed
      priced jobs.
    </p>
    <p>
      Any gigs thats are unable to be completed due to homeowner absence or
      fault will incur a 1 hour charge for hourly jobs and a 20% charge for
      fixed priced jobs.
    </p>
    <p>
      If a homeowner is unhappy with their service for any reason they should
      submit feedback through the app, and HomeKiq will make every effort to
      make amends.
    </p>
  </div>
</template>
